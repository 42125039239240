import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const IS_BOY_FIRST = true; //change to true for switch broadcast text

export const GIRL_NAME = 'Monica Handra';
export const GIRL_NAME_SHORT = 'Monica';
export const GIRL_FATHER_NAME = `Harijanto Handra (Toto)`;
export const GIRL_MOTHER_NAME = `Lim Njoek Tjin (Acen)`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of Mr. ${GIRL_FATHER_NAME}, <br />And Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME}, <br />Dan Ibu ${GIRL_MOTHER_NAME}`;

export const BOY_NAME = 'Michael Christophorus yoshua';
export const BOY_NAME_SHORT = 'Michael';
export const BOY_FATHER_NAME = `Cu Cing Sua (Yoshua)`;
export const BOY_MOTHER_NAME = `Tan Lim Yang (Yayang)`;
export const BOY_PARENT_NAME =
  LANG === 'en'
    ? `The Son of Mr. ${BOY_FATHER_NAME}, <br />And Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari Bapak ${BOY_FATHER_NAME}, <br />Dan Ibu ${BOY_MOTHER_NAME}`;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'michael_christophoruss';
export const IG_GIRL = 'monicaahan';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/adelinejune/michaelmonica/SEO.jpg?updatedAt=1698675538489`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1699765200;
export const EPOCH_RESEPSI_START = 1699790400;
export const EPOCH_END_EVENT = 1699797600;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-11-12');
export const DATE_RESEPSI = new Date('2023-11-12');

export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '12.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gereja Katolik Santo Philipus Rasul';
export const WEDDING_AKAD_LOC_ROAD =
  'Jln. Teluk Gong Raya No. 19 BC, Jl. Teluk Gong Raya, RT. 12/RW. 10, Pejagalan, Penjaringan, North Jakarta City, Jakarta 14450';

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).locale(LANG).format('dddd');
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_RESEPSI_TIME = '19.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Lausanne Ballroom Lt. 7 - Swissôtel Jakarta PIK Avenue`;
export const WEDDING_RESEPSI_LOC_ROAD = ` Pantai Indah Kapuk Boulevard , Kamal Muara , Penjaringan , North Jakarta City , Jakarta 14470`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD.MM.YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://michaelmonica.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_RESEPSI_LOC_NAME;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/FLGq7QhhfZRkRhHA8`;
export const GOOGLE_MAPS_ADDRESS = WEDDING_RESEPSI_LOC_NAME;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Stephen Sanchez - Until I Found You`;
export const SOUND_URL = 'https://youtu.be/JHWP_5t73zw';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/ZOy0rAsWyhs?si=aMy6YDl19I6Ullx_';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/ZOy0rAsWyhs?si=aMy6YDl19I6Ullx_';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbw0qVMd9tKzpbd9NAw8QY6d-mR2oPA5sJU8jvnX-7yv98WwOosKngrslwL0ojIj3gZNTA/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO =
  'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
